import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, UPDATE_PROFILE_PROGRESS, GET_PROFILE_PROGRESS, RESET_PROFILE_FLAG, CATEGORY_LIST_STATE, GET_CATEGORY_LIST_STATE } from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  category_list: []
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case UPDATE_PROFILE_PROGRESS:
      state = { ...state, profileProgress: action.payload }
      break
    case GET_PROFILE_PROGRESS:
      return { ...state.profileProgress}
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case CATEGORY_LIST_STATE:
      state = { ...state, category_list: action.payload }
      break
    case GET_CATEGORY_LIST_STATE:
      return { ...state.category_list }
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
