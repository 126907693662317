import React, { useEffect, useRef, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate, setCurrentUserProfilePercentWithResponse } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";
import MyQualificationForm from "../Partials/MyQualificationForm";

const MyQualification = (props) => {
  document.title = "My Qualifications - Virtual Skills Platform";

  const animatedComponents = makeAnimated();


  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [coursesList, setCoursesList] = useState([])
  const [institutesList, setInstitutesList] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [isDisableAddBtn, setIsDisableAddBtn] = useState(false)

  const containerRef = useRef(null);
  const topScrollRef = useRef(null);


  

 


  useEffect(() => {
    async function fetchData() {

        get(url.GET_USER_QUALIFICATION, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
            let responseData = response.data
            
            let qualifications = responseData.qualifications
            let coursesList = responseData.courses
            let institutesList = responseData.institutes

            setCurrentUserProfilePercentWithResponse(response);
            
            // console.log("RES ",response,qualifications)

            setCoursesList(coursesList)
            setInstitutesList(institutesList)
            setQualifications(qualifications);

            setLoading(false)

        }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
        }).finally(() => {
        });   
        
        // setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  const onDeleteQualification = (id) => {

    
    if(id) {
        
        setLoading(true)

        post(url.DELETE_USER_QUALIFICATION, { id: id }, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    
            let message = response?.response || "";
            let responseData = response?.data || [];

            setCurrentUserProfilePercentWithResponse(response);
            
            window.alert("Success!\n"+message)
            // window.history.go(-1);
            
            // const existingExp = [...experiences];
            // const updatedArray = removeObjectById(existingExp, id);
            setQualifications(responseData)
            setLoading(false)
            setTimeout(() => {
                scrollToTop()
            },300)
    
          }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
          }).finally(() => {
            setEditMode(false)
          }); 

    } else {
        
        const existingQualifications = [...qualifications];
        existingQualifications.pop();
        setQualifications(existingQualifications)
        setIsDisableAddBtn(false)
        scrollToTop()
        // const updatedArray = removeObjectById(existingExp, id);
    }

  }

  const removeObjectById = (array, idToRemove) => {
    return array.filter(item => item.id !== idToRemove);
  };

  const addNewQualification = () => {
    // validation.setFieldValue('job_role_id', selectedOptions);

    const updatedQualifications = [...qualifications];

    // console.log("QI ",institutesList[0].name,coursesList[0].title)
    // OLD OBJECT FOR QUALIFICATION
    // let obj = {
    //     institute: { label: institutesList[0].name, value: institutesList[0].institute_id },
    //     course: { label: coursesList[0].title, value: coursesList[0].courses_id },
    //     location : "",
    //     start_date : new Date(),
    //     end_date : new Date(),
    //     description : "",
    //     id : null,
    // }
    let obj = {
        institute: "",
        course: "",
        location : "",
        start_date : new Date(),
        end_date : new Date(),
        description : "",
        id : null,
    }

    updatedQualifications.push(obj);

    console.log("ADDING",updatedQualifications)
    setQualifications(updatedQualifications)
    setIsDisableAddBtn(true)
    setTimeout(() => {
        scrollToBottom();
    },300)


  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const lastChild = container.lastChild;
      lastChild.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    if (topScrollRef.current) {
      const container = topScrollRef.current;
      const firstChild = container.firstChild;
      firstChild.scrollIntoView({ behavior: "smooth", block: "start"  });
      
    }
  };



  return (
    <React.Fragment>
    { 
        isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  color="dark" /></div></section></> :
      <>
                    
                
                <Header />


            <section className="about_sec">
              
                    <div className="container">
                      
                        
                        {/* <!-- About --> */}
                        <div ref={topScrollRef} className="about_main">
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                <li><a href="#" title="About Me">About Me</a></li>
                                <li>My Qualifications</li>
                            </ul>
                            <div  className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-6">
                                    <h3>My Qualifications</h3>
                                </div>

                                <div className="col-md-6 col-sm-6 text-end">
                
                                <button  type="button" disabled={isDisableAddBtn} onClick={() => addNewQualification()}  className="btns blue mr-5"><img src="/images/plus.svg" alt="" />Add Qualification</button>
                               
                            </div>
                               
                            </div>

                            <div ref={containerRef} className="row">

                            {/* QUALIFICATION FORM */}
                            {
                                qualifications.map((q,i) => {

                                    return(<MyQualificationForm setIsDisableAddBtn={setIsDisableAddBtn} onDelete={(id) => onDeleteQualification(id) } initFormValues={q} courses={coursesList} institutes={institutesList} />)
                                })
                            }

                            </div>

                            {/* <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">

                                <Form className="needs-validation"  onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}> 

                                    <div className="col-md-12 col-sm-12 text-end">
                                        
                                        { isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                        
                                        { !isEditMode && <button type="button" onClick={() => setEditMode(true) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Experience</button>}
                                    </div>
                                    <div className="col-md-12 col-sm-12" style={{ marginTop : 20 }}>
                                                <MyQualificationForm isEditMode={isEditMode} job_roles={JOB_ROLES} validation={validation} />
                                    </div>
                                </Form>
                            </div> */}
                        </div>

                    </div>
                
            </section>

            
                <Footer />
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyQualification);