import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS

import sampleData from "./sample.json"
import additional from "./additional.json"



import Header from "./Header";
import Footer from "./Footer";

const SavedJobs = (props) => {
  document.title = "Saved Jobs - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isLoadMoreLoading, setLoadMoreLoading] = useState(false)
  
  const [jobList, setJobList] = useState([])
  const [jobListMaster, setJobListMaster] = useState([])
  const [searchQuery, setSearchQuery] = useState("");


  
  useEffect(() => {
    
  })

  const fetchData = () => {
    
 
    // `keywords=${searchQuery}&resultsToTake=${offset}&resultsToSkip=${skip}`
    post(url.GET_SAVED_REED_JOBS, {}).then((response) => {

      
      
      let jobs = response.data;
      // console.log("RES ",jobs)
        
        setJobList((prevData) => [...jobs])

        // console.log("J ",jobs)
        
        setLoading(false)
      }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setLoading(false)
    }).finally(() => {
    });   


  }


  useEffect(() => {
    
    
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

  return (
    <React.Fragment>
    {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
    { 
      <>
                    
                
                <Header />


            <section className="about_sec">
               
                    <div className="container">
                      
                        
                    {/* <!-- Experience --> */}
                    <div className="about_main">
                      <ul className="breadcrumb">
                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                        <li><a href="#" title="My Jobs">My Jobs</a></li>
                        <li>Saved Jobs</li>
                      </ul>

                      <div className="job_info">
                     

                        { isLoading ? 
                        <div className="text-center"><Spinners  /></div>
                        :
                        jobList.length > 0 ? jobList.map((j,i) => {

                            let job = JSON.parse(j?.job_detail || "{}")

                            return(
                              <div className="job_block">
                                <div className="job_item">
                                  {/* <div className="icon"><img src="/images/job_logo_1.png" alt="" /></div> */}
                                  <div className="detail">
                                    <h5><small style={{ marginBottom : 5 }}>{job.employerName}</small>{job.jobTitle}</h5>
                                    {/* <p className="clamp-text-2" style={{ marginTop : 10 }}>{job.jobDescription}</p> */}
                                  </div>
                                </div>
                                <div className="btn_info">
                                  <Link to={`/aboutme/saved-job/${job.jobId}`} className="btns gray">View Job</Link>
                                  {/* <a href="#" className="btns gray">View Job</a> */}
                                  {/* <a href={job?.jobUrl || "#"} target="_blank" className="btns blue">Apply Now</a> */}
                                </div>
                              </div>
                            )
                        }) 
                        :
                        <div className="text-center">
                          <p>No saved jobs found</p>
                        </div>
                        }

                        
                      </div>			
                     
                    </div>

                    </div>
            </section>

            <Footer />
                
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(SavedJobs);