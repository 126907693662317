import { post } from "./api_helper";
import { API_BASE_URL, REDIRECT_TO_COURSE_URL, SAVE_OR_UNSAVE_COURSE } from "./url_helper";

// export const BIRTHDATE_GROUP = [
//   {
//     label
//   }
// ]

const formatOption = (arr,label,val) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }
  
  return arr.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[val],
    };
  });
}

const isURL = (str) => {
  // Regular expression for URL validation
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}

const loadImageURL = (url) => {

  // console.log("IMAGE ",url,isURL(url))
  

  if(isURL(url)) {
    if(url.includes("googleusercontent")) {
      return url;
    } else {
      return url
    }
  } else {
    if(url) {
      if(url.includes("googleusercontent")) {
        return url;
      } else {
        return API_BASE_URL+url;
      }
    } else {
      return url;
    }
  }

};

// const handleImageError = (event) => {
//   let defaultSrc =  API_BASE_URL+"images/no-image.png"

//   // if(event.target.src.includes("googleusercontent.com")) {
//   //   console.log("SRC ",event.target.src)
//   //   event.target.src = event.target.src;
//   // } else {
//   //   // Replace the image source with the default source
//   //   event.target.src = defaultSrc;
//   // }
//   // Replace the image source with the default source
//   event.target.src = defaultSrc;
// };


const setMultiFieldValues = (arr,label,val) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }
  
  return arr.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[val],
    };
  });
}


const setDropdownValues = (form,existingValue,formKey,dataSource,label,value,isMulti) => { 

  // if(value == "courses_id") {
  //   console.log("F ",form,existingValue,formKey,dataSource,label,value,isMulti)
  // }

  
  // validation,"[1,2]","course_type",dropdowns?.courseTypes,"name","course_type_id"

  if (!dataSource || dataSource.length === 0) {
    // console.log("D ",dataSource,formKey)
    return []; // Return an empty array or handle the case as needed
  }
  // .setFieldValue('skills', selectedOptions); 


  if(isMulti) {
    let existingValues = JSON.parse(existingValue);

    // console.log("EXIS ",existingValue,formKey)
    let res = [];

    dataSource.map((originalObject) => {

      if (existingValues.includes(originalObject[value])) {
        res.push({
          label: originalObject[label],
          value: originalObject[value],
        })
      }

      
      
      form.setFieldValue(formKey, res); 

      return res;
      
    })

  } else {

    let existingValues = existingValue

    let res = {};

    // console.log("DD ",existingValues)

    // if (Array.isArray(existingValues)) {
    //   console.log('Variable is an array');
    // } else {
    //   console.log('Variable is not an array');
    // }

    dataSource.map((originalObject) => {

      if (existingValues == originalObject[value]) {
        res = {
          label: originalObject[label],
          value: originalObject[value],
        }

        form.setFieldValue(formKey, res);
        
      }

      return res;

      
    })

  }
  
  return dataSource.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[value],
    };
  });
}

const parseMultiPickerValues = (arr,key) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }

  let valuesArr = [];
  
  return arr.map((originalObject) => {
    return originalObject[key]
  });
}

const getCurrentDateInDefaultFormat = () => {
  const currentDate = new Date();

  const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'short' });
  const month = currentDate.toLocaleString('en-US', { month: 'short' });
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const timeZone = currentDate.toString().match(/\(([^)]+)\)/)[1];

  const formattedDate = `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${currentDate.getTimezoneOffset() > 0 ? '-' : '+'}${Math.abs(currentDate.getTimezoneOffset() / 60).toString().padStart(2, '0')}${Math.abs(currentDate.getTimezoneOffset() % 60).toString().padStart(2, '0')} (${timeZone})`;

  return formattedDate
}

const formatDate = (inputDateString) => {
  // console.log("DATE ",inputDateString,typeof inputDateString)

  let date = new Date();
  if(typeof inputDateString === 'string') {
    date = new Date(inputDateString);
  } else if (typeof inputDateString === 'object') {
    const localDatetime = new Date(inputDateString);
    const utcDatetime = new Date(localDatetime.getTime() - localDatetime.getTimezoneOffset() * 60000).toISOString();
    date = new Date(utcDatetime);
  }

  // const inputDate = typeof inputDateString === 'string' ? new Date(inputDateString) : new Date();
  const inputDate = date;
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  const year = inputDate.getFullYear();

  // const formattedDate = `${month}-${day}-${year}`;
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate
}

const trimAndAddEllipsis = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength - 3) + '...';
  } else {
    return inputString;
  }
}
// export const getUserProfile = () => get(url.GET_USER_PROFILE)

const getCurrentUser = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj
  } else {
    return null
  }
}

const getFooterCourses = () => {
  if (localStorage.getItem("footerCourses")) {
    const obj = JSON.parse(localStorage.getItem("footerCourses"));
    return obj
  } else {
    return null
  }
}

const setFooterCourses = (data) => {
  localStorage.setItem("footerCourses", JSON.stringify(data));
}

const getCurrentUserProfilePercent = () => {
  if (localStorage.getItem("profilePercent")) {
    const obj = JSON.parse(localStorage.getItem("profilePercent"));
    return obj
  } else {
    return null
  }
}

const getRemainingFields = () => {
  if (localStorage.getItem("remainingFields")) {
    const remainingFieldsObj = JSON.parse(localStorage.getItem("remainingFields"));
    return remainingFieldsObj
  } else {
    return []
  }
}

const getCompletedFields = () => {
  if (localStorage.getItem("completedFields")) {
    const completedFieldsObj = JSON.parse(localStorage.getItem("completedFields"));
    return completedFieldsObj
  } else {
    return []
  }
}

const setCurrentUserProfilePercent = (data) => {
  localStorage.setItem("profilePercent", JSON.stringify(data));
}

const setCurrentUserProfilePercentWithResponse = (response) => {

  let profilePercent = response?.profilePercent || 0
  let remainingFields = response?.remainingFields || []
  let completedFields = response?.completedFields || []

  localStorage.setItem("profilePercent", JSON.stringify(profilePercent));
  localStorage.setItem("remainingFields", JSON.stringify(remainingFields));
  localStorage.setItem("completedFields", JSON.stringify(completedFields));
}

const handleImageError = (event) => {
  let defaultSrc =  API_BASE_URL+"images/no-image.png"
  // Replace the image source with the default source
  event.target.src = defaultSrc;
};

const calculateDiscount = (originalPrice,discountedPrice,addPercentIcon = false) => {
  const original = parseFloat(originalPrice);
  const discounted = parseFloat(discountedPrice);

  if (!isNaN(original) && !isNaN(discounted) && original > discounted) {
    const discount = ((original - discounted) / original) * 100;
    // setDiscountPercentage(discount.toFixed(2) + '%');
    if(addPercentIcon) {
      return discount.toFixed(2) + '%'
    } else {
      return discount.toFixed(2)
    }
  } else {
    return null;
    // setDiscountPercentage('Invalid input');
  }
};

const randomNumber = () => Math.floor(Math.random() * 10000) + 1;




const redirectToCourse = (item) => {
          

  let userInfo = getCurrentUser();

  let body = {
      course_link : item?.course_link || "#",
      courses_id : item.courses_id
  }

  if(userInfo != null) {
      // console.log("USER INFO ",userInfo)
      body.user_id = userInfo.user_id
  } else {
      // console.log("USER INFO BLANK" )
  }

  if(body.course_link == "#") {
    window.open(body.course_link, '_blank');
    return
  } else {
    window.open(body.course_link, '_blank');
    post(REDIRECT_TO_COURSE_URL, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

    //   console.log("FETCHED ", response)

        let redirectUrl = response?.data || "#";

        // const { redirectUrl } = response.data;

        // Redirect to the new page in a new tab
        

        
        // setLoading(false)

    }).catch((e) => {
        window.alert("Sorry!\n" + e)
        // setLoading(false)
    }).finally(() => {
    //   setLoading(false)
    });
  }
}


export {
  formatOption,
  trimAndAddEllipsis,
  calculateDiscount,
  getCurrentUser,
  formatDate,
  getCurrentDateInDefaultFormat,
  randomNumber,
  handleImageError,
  parseMultiPickerValues,
  loadImageURL,
  getCurrentUserProfilePercent,
  setCurrentUserProfilePercent,
  setCurrentUserProfilePercentWithResponse,
  getFooterCourses,
  setFooterCourses,
  redirectToCourse,
  getRemainingFields,
  getCompletedFields,
  setDropdownValues
}
