import React from "react"
import { Navigate } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"


//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline";
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import Home from "pages/Home/Home"
import MyProfile from "pages/AboutMe/MyProfile"
import MyPanel from "pages/AboutMe/MyPanel"
import SearchJob from "pages/AboutMe/SearchJob"
import Dashboard from "pages/AboutMe/Dashboard"
import SearchJobDetail from "pages/AboutMe/SearchJobDetail"
import MyTraining from "pages/MyTraining/MyTraining"
import MySkill from "pages/AboutMe/MySkill"
import MyAnswer from "pages/AboutMe/MyAnswer"
import MyExperience from "pages/AboutMe/MyExperience"
import MyTrainingDetail from "pages/MyTraining/MyTrainingDetail"
import MyCareer from "pages/MyCareer/MyCareer"
import BuildMyCV from "pages/AboutMe/BuildMyCV"
import MyQualification from "pages/AboutMe/MyQualification"
import AboutUs from "pages/AboutUs/AboutUs"
import ContactUs from "pages/ContactUs/ContactUs"
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy"
import TermsCondition from "pages/TermsCondition/TermsCondition"
import MyCareerPath from "pages/MyCareer/MyCareerPath"
import MyTrainingPath from "pages/MyTraining/MyTrainingPath"
import SearchResults from "pages/Search/SearchResults"
import CourseList from "pages/Courses/CourseList"
import SavedJobs from "pages/AboutMe/SavedJobs"
import RecommendedJobs from "pages/AboutMe/RecommendedJobs"
import JobHeatmap from "pages/AboutMe/JobHeatmap"
import MyCareerPathApp from "pages/MyCareer/MyCareerPathApp"
import MyTrainingPathApp from "pages/MyTraining/MyTrainingPathApp"
import SavedCourses from "pages/AboutMe/SavedCourses"


const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <AboutUs /> },
  { path: "/contactus", component: <ContactUs /> },
  { path: "/privacypolicy", component: <PrivacyPolicy /> },
  { path: "/termscondition", component: <TermsCondition /> },
  { path: "/aboutme/my-profile", component: <MyProfile /> },
  { path: "/aboutme/my-panel", component: <MyPanel /> },
  { path: "/aboutme/jobs", component: <SearchJob /> },
  { path: "/jobs/search", component: <SearchJob /> },
  { path: "/jobs/analytics", component: <JobHeatmap /> },
  { path: "/aboutme/job/:id", component: <SearchJobDetail /> },
  { path: "/aboutme/saved-job/:id", component: <SearchJobDetail /> },
  { path: "/jobs/recommended-jobs", component: <RecommendedJobs /> },
  { path: "/jobs/saved-jobs", component: <SavedJobs /> },
  { path: "/aboutme/my-skill", component: <MySkill /> },
  { path: "/aboutme/my-answer", component: <MyAnswer /> },
  { path: "/aboutme/my-experience", component: <MyExperience /> },
  { path: "/aboutme/my-qualification", component: <MyQualification /> },
  { path: "/aboutme/experience-profile", component: <BuildMyCV /> },
  { path: "/aboutme/saved-courses", component: <SavedCourses /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/training/my-training-programs", component: <MyTraining /> },
  { path: "/training/my-training-path", component: <MyTrainingPath /> },
  { path: "/training/my-training-detail", component: <MyTrainingDetail /> },
  { path: "/career/my-career", component: <MyCareer /> },
  { path: "/career/my-career-path", component: <MyCareerPath /> },
  // { path: "/Courses/CourseSearch", component: <CourseSearch /> },
  
  { path: "/course-list", component: <CourseList /> },
  { path: "/search-results", component: <SearchResults /> },
  { path: "/logout", component: <Logout /> },
  
  // //Extra Pages
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-invoice", component: <PagesInvoice /> },
  { path: "/pages-directory", component: <PagesDirectory /> },
  { path: "/pages-blank", component: <PagesBlank /> },

  // this route should be at the end of all other routes
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
]

const appRoutes = [
  { path: "/app-my-career-path/:id", component: <MyCareerPathApp /> },
  { path: "/app-my-training-path/:id", component: <MyTrainingPathApp /> },
]
const authRoutes = [
  // { path: "/", component: <Home /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes, appRoutes }