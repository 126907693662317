import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, randomNumber, setCurrentUserProfilePercentWithResponse, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";

const MyAnswer = (props) => {
    document.title = "My MyAnswer - Virtual Skills Platform";

    const animatedComponents = makeAnimated();

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [isEditMode, setEditMode] = useState(false)
    const [questionaire, setQuestionaire] = useState([])
    const [lockValues, setLockValues] = useState(null)

    // const [formValues, setFormValues] = useState({
    //     question_id: '1',
    //     selected_answer: "My family and social environment is supportive of my desire to undertake further study",
    // })
    const [formValues, setFormValues] = useState({})
    const QUESTIONS = [
        { label: "My current employment status is ", value: "1" },
        { label: "Question 2 ?", value: "2" },
        { label: "Question 3 ?", value: "3" }
    ]

    const DEFAULTANSWERS = [
        { label: "Unsure", value: "Unsure" },
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
    ]

    const [defaultQuestions, setDefaultQuestions] = useState([
        { question : "I have access to computers and other media devices", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I do have a good internet connection", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have studied before through online learning or adult learning", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have recently online learned, including undertaking an online short course", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
    ])
    
    const EMPLOYMENT_ANSWERS = [
        { label: "Full-Time Caregiver", value: "Full-Time Caregiver" },
        { label: "Part-Time Caregiver", value: "Part-Time Caregiver" },
        { label: "More", value: "More" }
    ]

    const myAnswersvalidation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: formValues,
        validationSchema: Yup.object({
            // question_id: Yup.object().required("Please enter question"),
            // selected_answer: Yup.string().required("Please enter answer"),
        }),
        onSubmit: async (values) => {
            // Handle form submission logic here

            setLoading(true);


            const questionsData = [];

            // Iterate through the keys in `values`
            Object.keys(values).forEach(key => {
            // Check if the key starts with "questions_*"
            if (key.startsWith('questions_')) {
                // If it matches, store the key-value pair in `removedValues`
                // questionsData[key] = values[key];

                let newKey = key.split("_")[1];

                let obj = { question_id : newKey, answer_id : values[key] }

                questionsData.push(obj);
                // Remove the key from the original `values` object
                // delete values[key];
            }
            });

            console.log("SUBMITTED",values,questionsData)






            // setTimeout(() => {
            //     setLoading(false)
            //     setEditMode(false)
            // }, 3000)

              post(url.CREATE_USER_ANSWERS, { questions : questionsData}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {


                let message = response?.response || "";
                let responseData = response?.data || [];

                setCurrentUserProfilePercentWithResponse(response);

                window.alert("Success!\n"+message)
                // window.history.go(-1);
                setLoading(false)
                setLockValues(null)
                setEditMode(false)

              }).catch((e) => {
                window.alert("Sorry!\n"+e)
              }).finally(() => {
                // setLoading(false)
              });   


        },
    });


    const handleAnswerSelect = (selectedOptions,key) => {
        myAnswersvalidation.setFieldValue(key, selectedOptions);
    };


    useEffect(() => {
        async function fetchData() {

            get(url.GET_ALL_QUESTIONS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                console.log("QA ",response)

                let responseData = response.data;

                setCurrentUserProfilePercentWithResponse(response);
                
                loadDefaultQuestionsAnswers(responseData.questions,responseData.answers);
                

            }).catch((e) => {
                window.alert("Sorry!\n"+e)
                setLoading(false)
            }).finally(() => {
            });   

            // setLoading(false)


        }


        async function loadDefaultQuestionsAnswers(defaultQuestionsData,selectedAnswers) {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   
            

            

            // let defaultFormValues = formValues

            // let questionObj = {}


            // defaultQuestionsData.map((data,i) => {
            //     questionObj[data.question_key] = data.defaultAnswer
            // })


            let questionObj = {}


            defaultQuestionsData.map((data,i) => {

                let isFoundAnswer = false 

                selectedAnswers.map((ans,j) => {

                    if(ans.question_id == data.question_id){

                        data.answers.map((qa,j) => {

                            if(ans.answer_id == qa.answer_id) {
                                questionObj["questions_"+data.question_id] = { label: qa.answer, value: qa.answer_id }
                                isFoundAnswer = true;
                                console.log("TRUE",ans,data)
                            }
                        })

                    }

                })

                // if(!isFoundAnswer) {
                //     questionObj["questions_"+data.question_id] = { label: data.answers[0].answer, value: data.answers[0].answer_id }
                // }

            })


            let questionMaster = []
            // console.log("D ",defaultQuestionsData)


            defaultQuestionsData.map((data,i) => {

                let answers = data.answers

                let answersList = [];
                answers.map((ans,j) => {
                    answersList.push({ label: ans.answer, value: ans.answer_id })
                })


                data.answers_list = answersList

                questionMaster.push(data)
            })

            // console.log("Q ",questionObj,questionMaster)
            setQuestionaire(questionMaster);
            setFormValues({ ...formValues, ...questionObj })
            setLoading(false)


            


        }

        
        
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    const handleQuestionSelect = (selectedOptions) => {
        myAnswersvalidation.setFieldValue('question_id', selectedOptions);
    };



const handleEditOn = () => {
    setLockValues(myAnswersvalidation.values)
    setEditMode(true)
  }
  
  const handleEditCancel = () => {
    myAnswersvalidation.setValues(lockValues)
    setLockValues(null)
    setEditMode(false)
  }


    return (
        <React.Fragment>
            {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
            { 
                isLoading ? <><div className="container text-center"><Spinners  /></div></> :
                <>




                    <section className="about_sec">
                        <Form className="needs-validation" onSubmit={(e) => {
                            e.preventDefault();
                            
                            return false;
                        }}>
                            <div className="">


                                {/* <!-- About --> */}
                                <div className="about_main">
                                    {/* <ul className="breadcrumb">
                                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                        <li><a href="#" title="About Me">About Me</a></li>
                                        <li>My Answer</li>
                                    </ul> */}
                                    <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                        <div className="col-md-auto col-sm-12">
                                            <h3>My Answer</h3>
                                        </div>
                                        <div className="col-md-auto col-sm-12 text-end">
                                            {/* <a href="about_me_edit.html" className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Profile</a> */}
                                            {isEditMode && <button type="buttom" onClick={(e) => {
                                                e.preventDefault();
                                                myAnswersvalidation.handleSubmit();
                                            }} className="btns green me-2">{isLoading ? <Spinners /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                            {/* <Button className="btns green" color="primary" type="submit">
                                        Save
                                    </Button> */}
                                            { isEditMode && <button type="button" onClick={() => handleEditCancel()} className="btns gray"><img src="/images/close_sm_ic.svg" alt="" />Cancel</button>}
                                            {!isEditMode && <button type="button" onClick={() => handleEditOn()} className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Answer</button>}
                                        </div>
                                    </div>
                                    {/* <div className="about_block">
                                        
                                    </div> */}

                                <div className="row">
                                    { questionaire.map((data,i) => {

                                        // console.log("QUES ",validation.errors,validation.values)

                                        return <div key={i} className="col-md-6">
                                        <div className="information_item">
                                            <div className="col-md-9">
                                                <p>{data.user_question_label}</p>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                    isEditMode ?
                                                        <>
                                                            <Select
                                                                value={myAnswersvalidation.values["questions_"+data.question_id]}
                                                                isMulti={false}
                                                                onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"questions_"+data.question_id) }
                                                                options={data.answers_list}
                                                                id={"questions_"+data.question_id}
                                                                className="removeborder"
                                                                classNamePrefix="selectpicker"
                                                                isValid={!myAnswersvalidation.errors["questions_"+data.question_id] && myAnswersvalidation.touched["questions_"+data.question_id]}
                                                            />
                                                            {myAnswersvalidation.touched["questions_"+data.question_id] && myAnswersvalidation.errors["questions_"+data.question_id] ? (
                                                                <div className="invalid-feedback">{myAnswersvalidation.errors["questions_"+data.question_id]}</div>
                                                            ) : null}
                                                        </>
                                                        :
                                                        <h5 className="text-center">{myAnswersvalidation.values?.["questions_"+data.question_id]?.label || ''}</h5>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    }) }
                                </div>



                                </div>

                            </div>
                        </Form>
                    </section>


                    {/* <Footer /> */}
                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(MyAnswer);