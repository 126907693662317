import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, UPDATE_PROFILE_PROGRESS, GET_PROFILE_PROGRESS, RESET_PROFILE_FLAG, CATEGORY_LIST_STATE, GET_CATEGORY_LIST_STATE } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const setProfileProgress = obj => {
  return {
    type: UPDATE_PROFILE_PROGRESS,
    payload: { obj },
  }
}

export const getProfileProgress = obj => {
  return {
    type: GET_PROFILE_PROGRESS
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const setCategoryList = category_list => ({
  type: CATEGORY_LIST_STATE,
  payload: category_list,
})

export const getCategoryList = () => ({
  type: GET_CATEGORY_LIST_STATE
})


export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
