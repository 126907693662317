export const API_BASE_URL = "https://api.virtualskillshub.co.uk/";
 export const API_URL = "https://api.virtualskillshub.co.uk/api";
// export const API_BASE_URL = "http://localhost:8080/";
// export const API_URL = "http://localhost:8080/api";


//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_PROD_JWT_LOGIN = "/auth/login"
export const POST_PROD_DIRECT_JWT_LOGIN = "/auth/login-direct"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";
export const DELETE_MESSAGE = "/delete/message";

//TASKS
export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"


// FRONTEND URLs START
export const GET_MY_PANEL = "/aboutme/mypanel";
export const SAVE_DESIRED_ROLE = "/aboutme/save-desired-role";



// MY ANSWERS (QUESTIONS)
export const GET_ALL_QUESTIONS = "/qa/get-user";
export const CREATE_USER_ANSWERS = "/qa/create-user-answers";
// export const GET_COURSE_DROPDOWNS = "/course/get-dropdowns";
// export const CREATE_COURSE = "/course/create";


// CV URL STARTED
export const SAVE_USER_LINKS = "/cv/save-user-links";
export const GET_USER_CV_LINKS = "/cv/user-links"
export const SAVE_USER_SKILLS = "/cv/save-user-skills";
export const SAVE_USER_WITH_NEW_SKILLS = "/cv/add-new-user-skills";
export const GET_USER_CV_SKILLS = "/cv/user-skills"
export const SAVE_USER_SUMMARY = "/cv/save-user-summary";
export const GET_USER_CV_SUMMARY = "/cv/user-summary"

// CV URL ENDED

// CV URL STARTED
// export const GET_REED_JOBS = "https://www.reed.co.uk/api/1.0/search";
export const GET_REED_JOBS = "/aboutme/jobs";
export const GET_REED_JOB_DETAIL = "/aboutme/job";
export const SAVE_REED_JOB_DETAIL = "/aboutme/save-job";
export const GET_SAVED_REED_JOBS = "/aboutme/get-saved-jobs";
export const GET_RECOMMENED_REED_JOBS = "/aboutme/get-recommened-jobs";
export const GET_REED_JOB_HEATMAP = "/aboutme/get-jobs-heatmap";

// CV URL ENDED

// AI PATHs START

export const GENERATE_USER_TRAINING_PATH = "/ai/generate-training-path";
export const GENERATE_USER_CAREER_PATH = "/ai/generate-career-path";
export const GENERATE_USER_TRAINING_PATH_APP = "/ai/generate-training-path/";
export const GENERATE_USER_CAREER_PATH_APP = "/ai/generate-career-path/";
export const GET_SEARCH_RESULT = "/ai/search";
export const GET_RECOMMENDATIONS = "/ai/recommendation";
export const REDIRECT_TO_COURSE_URL = "/ai/redirect";
export const SAVE_OR_UNSAVE_COURSE = "/ai/update-course-save";
export const GET_SAVED_COURSES = "/ai/saved-courses";
// export const GET_SEARCH_RESULT = "/ai/search?c=&q=Digital";

// AI PATHs END

// FRONTEND URLs END



// Experience
export const GET_EXPERIENCES = "/experience/get";
export const UPDATE_EXPERIENCE = "/experience/update/";
export const CREATE_EXPERIENCE = "/experience/create/";
export const DELETE_EXPERIENCE = "/experience/delete/";

// Qualification
export const GET_USER_QUALIFICATION = "/qualification/get-user";
export const UPDATE_USER_QUALIFICATION = "/qualification/update-user/";
export const CREATE_USER_QUALIFICATION = "/qualification/create-user/";
export const DELETE_USER_QUALIFICATION = "/qualification/delete-user/";

// User
export const GET_USER_INFO = "/user/get";
export const UPDATE_USER_INFO = "/user/update";
export const USER_SIGNUP = "/user/register";
export const SEND_USER_VERIFICATION_OTP = "/user/send-otp";
export const USER_VERIFICATION = "/user/verify-email";
export const FORGOT_PASS_VERIFICATION_OTP = "/user/send-forgot-password-otp";
export const CHANGE_PASS = "/user/change-password";

// COURSES
export const GET_COURSE_DROPDOWNS = "/course/get-dropdowns";
export const CREATE_COURSE = "/course/create";
export const GET_ALL_COURSES = "/course/get";

// Qualifications
export const CREATE_QUALIFICATION = "/qualification/create";
export const GET_ALL_QUALIFICATIONS = "/qualification/get";
export const GET_QUALIFICATIONS_CATEGORIES = "/qualification/get-categories";
export const GET_QUALIFICATION = "/qualification/get/";
export const UPDATE_QUALIFICATION = "/qualification/update";
export const DELETE_QUALIFICATION = "/qualification/delete";

export const CREATE_QUALIFICATION_CATEGORY = "/qualification/category/create";
// export const GET_ALL_QUALIFICATIONS_CATEGORIES = "/qualification/category/get-categories";
export const GET_ALL_QUALIFICATIONS_CATEGORIES = "/qualification/category/get";
export const GET_QUALIFICATION_CATEGORY = "/qualification/category/get/";
export const UPDATE_QUALIFICATION_CATEGORY = "/qualification/category/update";
export const DELETE_QUALIFICATION_CATEGORY = "/qualification/category/delete";


//Language
export const GET_LANGUAGE = "/";

//Job Role
export const CREATE_JOB_ROLES = "/jobrole/create";
export const GET_JOB_ROLES_CATEGORIES = "/jobrole/get-categories";
export const GET_ALL_JOB_ROLES = "/jobrole/get";
export const GET_JOB_ROLE = "/jobrole/get/";
export const UPDATE_JOB_ROLE = "/jobrole/update";
export const DELETE_JOB_ROLE = "/jobrole/delete";

export const CREATE_JOB_ROLE_CATEGORY = "/jobrole/category/create";
// export const GET_JOB_ROLES_CATEGORIES = "/jobrole/get-categories";
export const GET_ALL_JOB_ROLE_CATEGORIES = "/jobrole/category/get";
export const GET_JOB_ROLE_CATEGORY = "/jobrole/category/get/";
export const UPDATE_JOB_ROLE_CATEGORY = "/jobrole/category/update";
export const DELETE_JOB_ROLE_CATEGORY = "/jobrole/category/delete";


//Skill
export const CREATE_SKILLS = "/skill/create";
export const GET_SKILLS_CATEGORIES = "/skill/get-categories";
export const GET_ALL_SKILLS = "/skill/get";
export const GET_SKILL = "/skill/get/";
export const UPDATE_SKILL = "/skill/update";
export const DELETE_SKILL = "/skill/delete";

//Skill Category
export const CREATE_SKILL_CATEGORY = "/skill/category/create";
// export const GET_ALL_SKILLS_CATEGORY = "/skill/get-categories";
export const GET_ALL_SKILLS_CATEGORIES = "/skill/category/get";
export const GET_SKILL_CATEGORY = "/skill/category/get/";
export const UPDATE_SKILL_CATEGORY = "/skill/category/update";
export const DELETE_SKILL_CATEGORY = "/skill/category/delete";



//Institue
export const CREATE_INSTITUTE = "/institute/create";
export const GET_INSTITUTES_CATEGORIES = "/institute/get-categories";
export const GET_ALL_INSTITUTES = "/institute/get";
export const GET_INSTITUTE = "/institute/get/";
export const UPDATE_INSTITUTE = "/institute/update";
export const DELETE_INSTITUTE = "/institute/delete";

// Institute Category
export const CREATE_INSTITUTE_CATEGORY = "/institute/category/create";
export const GET_ALL_INSTITUTES_CATEGORY = "/institute/get-categories";
// export const GET_ALL_INSTITUTES_CATEGORY = "/institute/get";
export const GET_INSTITUTE_CATEGORY = "/institute/category/get/";
export const UPDATE_INSTITUTE_CATEGORY = "/institute/category/update";
export const DELETE_INSTITUTE_CATEGORY = "/institute/category/delete";


// Availability
export const CREATE_AVAILABILITY = "/availability/create";
export const GET_AVAILABLITY_CATEGORIES = "/availability/get-categories";
export const GET_ALL_AVAILABILITIES = "/availability/get";
export const GET_AVAILABILITY = "/availability/get/";
export const UPDATE_AVAILABLITY = "/availability/update";
export const DELETE_AVAILABILITY = "/availability/delete";


// COURSE TYPE
export const CREATE_COURSE_TYPE = "/course-type/create";
export const GET_ALL_COURSE_TYPES = "/course-type/get";
export const GET_COURSE_TYPE = "/course-type/get/";
export const UPDATE_COURSE_TYPE = "/course-type/update";
export const DELETE_COURSE_TYPE = "/course-type/delete";

// WORK LOAD TYPE
export const CREATE_WORK_LOAD_TYPE = "/work-load-type/create";
export const GET_ALL_WORK_LOAD_TYPES = "/work-load-type/get";
export const GET_WORK_LOAD_TYPE = "/work-load-type/get/";
export const UPDATE_WORK_LOAD_TYPE = "/work-load-type/update";
export const DELETE_WORK_LOAD_TYPE = "/work-load-type/delete";


// WORK LOAD TYPE
export const CREATE_BADGE = "/badge/create";
export const GET_ALL_BADGE = "/badge/get";
export const GET_BADGE = "/badge/get/";
export const UPDATE_BADGE = "/badge/update";
export const DELETE_BADGE = "/badge/delete";

// MASTER CATEOGRY
export const GET_COURSE_CATEGORIES = "/category/get-list";



//Mastdata
// export const GET_AVAILABILITY = "/";
// export const GET_BADGE  = "/";
// export const GET_COURSETYPE = "/";
// export const GET_INSTITUTE_CATEGORIES = "/";
// export const GET_JOB_ROLE_CATEGORIES = "/";
export const GET_MYANS_QUESTION = "/";
// export const GET_SKILLS_CATEGORIES = "/";
export const GET_MYSKILLS_QUESTION = "/";
// export const GET_WORKLOAD_TYPE = "/";