import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate, getCurrentDateInDefaultFormat } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality


const MyExperienceForm = (props) => {

  const animatedComponents = makeAnimated();
 
    
  const dispatch = useDispatch()
  const [isViewOnlyMode, setIsViewOnlyMode] = useState(props?.isViewOnlyMode || false);
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [coursesList, setCoursesList] = useState([])
  const [institutesList, setInstitutesList] = useState([])
  const [lockValues, setLockValues] = useState(null)

//   const [formValues, setFormValues] = useState({
//     job_role: { label: "Software Engineer", value: "Software Engineer" },
//     company_name : "BCP Council",
//     location : "BH2, Bournemouth, England",
//     start_date : new Date(),
//     end_date : new Date(),
//     job_description : "This is job description",
//     cv_id : "1",
   
//   })
  const [formValues, setFormValues] = useState({})


  const [textareabadge, settextareabadge] = useState(0)
  const [placementbadge, setplacementbadge] = useState(false)
  const [textcount, settextcount] = useState(0)

//   const validation = props.validation
//   const isEditMode = props.isEditMode

  

// institute: Yup.object().nullable().required("Please enter institute name"),
// course: Yup.object().nullable().required("Please enter course name"),
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
        institute: Yup.string().required("Please enter institute name"),
        course: Yup.string().required("Please enter course name"),
        location: Yup.string().required("Please enter location"),
        start_date: Yup.date().required("Please enter start date"),
        end_date: Yup.date().required("Please enter end date"),
        description: Yup.string().required("Please enter skills"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);

      let newBody = Object.assign({}, values);

    //   newBody.course_id = newBody.course.value;
    //   delete newBody.course

    //   newBody.institute_id = newBody.institute.value;
    //   delete newBody.institute

    // Name free text for below both
      newBody.course_name = newBody.course;
      delete newBody.course

      newBody.institute_name = newBody.institute;
      delete newBody.institute
      
      console.log("SUBMITTED",newBody)
      if(!newBody.id) {
        // console.log("NULL ",newBody)
        delete newBody.id;
        post(url.CREATE_USER_QUALIFICATION, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    
            let message = response?.response || "";
            let responseData = response?.data || [];
            
            window.alert("Success!\n"+message)
            // window.history.go(-1);
            setLoading(false)
            props.setIsDisableAddBtn(false)
            
            
          }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
          }).finally(() => {
            setEditMode(false)
          }); 
      } else {
        post(url.UPDATE_USER_QUALIFICATION, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    
            let message = response?.response || "";
            let responseData = response?.data || [];
            
            window.alert("Success!\n"+message)
            // window.history.go(-1);
            setLoading(false)
            props.setIsDisableAddBtn(false)
    
          }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
          }).finally(() => {
            setEditMode(false)
          }); 
      }
      return
    //   setTimeout(() => {
    //     setLoading(false)
    //     setEditMode(false)
    //   },3000)
        
      
     
    },
  });
  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        let courses = props.courses;
        let institutes = props.institutes;

        let crs = []

        courses.map((c,i) => {
            crs.push({ label : c.title, value : c.courses_id })
        })

        let ins = []

        institutes.map((i,index) => {
            ins.push({ label : i.name, value : i.institute_id })
        })

        setCoursesList(crs)
        setInstitutesList(ins)

        // console.log("EXP ",props)

        let existingData = props.initFormValues;

        
        if(!existingData?.id) {
            setEditMode(true)
            setFormValues(existingData)
        } else {
            // institute: { label: existingData?.institute_name, value: existingData?.institute?.institute_name },
            // course: { label: existingData?.course_name, value: existingData?.course_name },
            let obj = {
                institute: existingData?.institute_name,
                course: existingData?.course_name,
                location : existingData?.location || "",
                start_date : existingData?.start_date || new Date(),
                end_date : existingData?.end_date || new Date(),
                description : existingData?.description || "",
                id : existingData?.id || null,
            }
    
            setFormValues(obj)
        }



        setLoading(false)
      
    
    }
    // console.log("DATA ",props)
    // setIsViewOnlyMode(props.isViewOnlyMode)
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

const handleDropdownSelect = (selectedOptions,key) => {
    validation.setFieldValue(key, selectedOptions);
};

const onDelete = (id) => {
    props.onDelete(id)
}


const handleEditOn = () => {
    setLockValues(validation.values)
    setEditMode(true)
}

const handleEditCancel = () => {
    // console.log("MESSAGE ",)
    validation.setValues(lockValues)
    setLockValues(null)
    setEditMode(false)
    settextareabadge(false)
}


const textareachange = (event) => {
    const count = event.target.value.length
    
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }
  

  return (
    <React.Fragment>
        <>
        <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">

        <Form className="needs-validation"  onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}> 

            <div className="col-md-12 col-sm-12 text-end">
                
                { !isViewOnlyMode && isEditMode && <button type="submit" className="btns green me-2">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                {/* <Button className="btns green" color="primary" type="submit">
                    Save
                </Button> */}
                { isEditMode && <button type="button" onClick={() => handleEditCancel() } className="btns gray"><img src="/images/close_sm_ic.svg" alt="" />Cancel</button>}
                { !isViewOnlyMode && !isEditMode && <button type="button" onClick={() => handleEditOn() } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit</button>}
                {  !isViewOnlyMode && <button type="button" onClick={() => onDelete(validation.values.id) } className="btns red ms-2">
                    <img src="/images/trash_ic.svg" style={{height : 15}} alt="" />
                    Delete
                    </button>}
            </div>
            <div className="col-md-12 col-sm-12" style={{ marginTop : 20 }}></div>
        {/* <!-- Partner --> */}
        <div className="about_block">
                                <div className="row">
                               
                                    
                                    <div className="col-md-6 col-sm-4">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Sex</label>
                                            <h5>Female </h5> */}

                                            <Label>Institute</Label>
                                            

                                            {
                                                isEditMode ?
                                                    <>
                                                        {/* <Select
                                                            value={validation.values.institute}
                                                            isMulti={false}
                                                            onChange={(selectedOptions) => handleDropdownSelect(selectedOptions,"institute")}
                                                            options={institutesList}
                                                            id="institute"
                                                            classNamePrefix="selectpicker"
                                                            isValid={!validation.errors.institute && validation.touched.institute}
                                                        /> */}
                                                        <Input
                                                            name="institute"
                                                            placeholder="Enter Institute Name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.institute || ''}
                                                            invalid={validation.touched.institute && validation.errors.institute ? true : false}
                                                        />
                                                        {validation.touched.institute && validation.errors.institute ? (
                                                            <div className="invalid-feedback">{validation.errors.institute}</div>
                                                        ) : null}
                                                    </>
                                                    :
                                                    <h5>{validation.values?.institute || ""}</h5>
                                            }
                                           
                                            
                                           
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-4">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Sex</label>
                                            <h5>Female </h5> */}

                                            <Label>Course</Label>
                                            

                                            {
                                                isEditMode ?
                                                    <>
                                                        {/* <Select
                                                            value={validation.values.course}
                                                            isMulti={false}
                                                            onChange={(selectedOptions) => handleDropdownSelect(selectedOptions,"course")}
                                                            options={coursesList}
                                                            id="course"
                                                            classNamePrefix="selectpicker"
                                                            isValid={!validation.errors.course && validation.touched.course}
                                                        /> */}
                                                        <Input
                                                            name="course"
                                                            placeholder="Enter Course Name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.course || ''}
                                                            invalid={validation.touched.course && validation.errors.course ? true : false}
                                                        />
                                                        {validation.touched.course && validation.errors.course ? (
                                                            <div className="invalid-feedback">{validation.errors.course}</div>
                                                        ) : null}
                                                    </>
                                                    :
                                                    <h5>{validation.values?.course || ""}</h5>
                                            }
                                           
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                   <div className="col-md-6 col-sm-4">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Location</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="location"
                                                    placeholder="Enter location"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.location || ''}
                                                    invalid={validation.touched.location && validation.errors.location ? true : false}
                                                />
                                                {validation.touched.location && validation.errors.location ? (
                                                    <div className="invalid-feedback">{validation.errors.location}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.location || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div className="input_group">
                                            <Label  htmlFor="validationCustom01">Start Date</Label>

                                            {
                                                isEditMode ? 
                                                <>
                                                    
                                                    <Flatpickr
                                                        name="start_date"
                                                        className={`form-control d-block ${validation.touched.start_date && validation.errors.start_date ? 'is-invalid' : ''}`}
                                                        placeholder="MM/DD/YYYY"
                                                        value={validation.values.start_date}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d/m/Y",
                                                            dateFormat: "Y-m-d",
                                                            maxDate: new Date()
                                                        }}
                                                        onChange={(selectedDates) => {
                                                            validation.setFieldValue('start_date', selectedDates[0]);
                                                        }}
                                                    />
                                                    {validation.touched.start_date && validation.errors.start_date ? (
                                                        <div className="text-danger">{validation.errors.start_date}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>{formatDate(validation.values?.start_date || new Date()) || ''}</h5>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-3 col-sm-4">
                                        <div className="input_group">
                                        <Label  htmlFor="validationCustom01">End Date</Label>
                                        {
                                            isEditMode ? 
                                            <>
                                                <Flatpickr
                                                    name="end_date"
                                                    className={`form-control d-block ${validation.touched.end_date && validation.errors.end_date ? 'is-invalid' : ''}`}
                                                    placeholder="MM/DD/YYYY"
                                                    value={validation.values.end_date}
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d/m/Y",
                                                        dateFormat: "Y-m-d",
                                                        minDate: validation.values.start_date
                                                    }}
                                                    onChange={(selectedDates) => {
                                                        validation.setFieldValue('end_date', selectedDates[0]);
                                                    }}
                                                />
                                                {validation.touched.end_date && validation.errors.end_date ? (
                                                    <div className="text-danger">{validation.errors.end_date}</div>
                                                ) : null}
                                            </>
                                            :
                                            <h5>{formatDate(validation.values?.end_date || getCurrentDateInDefaultFormat() ) || ''}</h5>
                                        }
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className="row">
                              <div className="col-md-12 col-sm-12">
                                        <div className="input_group">
                                            
                                            <Label>Skills</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="description"
                                                    placeholder="Skill 1, Skill 2, Skill 3..."
                                                    type="textarea"
                                                    style={{ minHeight : 100 }}
                                                    maxLength="225"
                                                    onChange={(e) => {
                                                        validation.setFieldValue('description',e.target.value);
                                                        textareachange(e)
                                                      }
                                                    }
                                                    // onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.description || ''}
                                                    invalid={validation.touched.description && validation.errors.description ? true : false}
                                                />
                                                {validation.touched.description && validation.errors.description ? (
                                                    <div className="invalid-feedback">{validation.errors.description}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.description || ''}</h5>
                                            }
                                             {textareabadge ? (
                                                <span className="badgecount badge badge-success">
                                                  {" "}
                                                  {textcount} / 225{" "}
                                                </span>
                                              ) : null}
                                        </div>
                                    </div>
                                </div>
                    </div>
            </Form>
        </div>
      </>
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyExperienceForm);